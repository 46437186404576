import React from 'react';
import Reveal from 'react-reveal';
import Layout from "../components/layouts";
import { Parallax } from 'react-parallax';
import carpoolHeader from "../images/header-carpool.jpg";
import carpoolGallery4 from "../images/carpool-gallery-4.jpg";
import carpoolGallery6 from "../images/carpool-gallery-6.jpg";
import carpoolGallery7 from "../images/carpool-gallery-7.jpg";
import carpoolGallery9 from "../images/carpool-gallery-12.jpg";
import carpoolGallery10 from "../images/carpool-gallery-11.jpg";
const Carpool = () => {
  return (
    <Layout navBg>
    <div className="carpoolchella-page">
        <Parallax className="page-header" bgImage={carpoolHeader} strength={400}>
            <div style={{height: '100vh', maxHeight: '550px'}}>
                <div className="page-header-overlay" />
                <h1 className="page-header-title white">Carpoolchella Design Contest</h1>
            </div>
        </Parallax>
        <div className="carpoolchella-inner">
          <div className="carpoolchella-description">
              <h1>Call For Artists! Submit Your Coachella Car Design and Possibly Win VIP Passes</h1>
              <p>Carpooling to the festival saves gas, time, money, and emissions. It's also a perfect opportunity to roll with your favorite people in the world, and if you're up for it - get creative.</p>
              <p>The Carpoolchella program is one of Global Inheritance's longest-running programs at the festival - awarding prizes of all sizes (including VIP For Life!) since 2007. Every year winners are selected at random by our team of spotters who wait in the parking lots looking for you and your crew as you arrive. This year, we've introducing a new way to win, which also increases your odds of getting noticed… The Carpooling Design Challenge!</p>
              <p>Show us your best ideas for attracting attention and promoting carpooling on your way to the festival and you could win VIP passes to the festival. If you already have festival passes - great - submit your design concept and you could be selected for a VIP Upgrade.</p>
              <p>Your proposed car design will be judged on its creativity, originality, your history of creative work, and the safety/durability of the idea. One lucky music fan (and their plus 3, so its proper carpool) will receive VIP passes to the festival each weekend.</p>
              <p>We will also be upgrading festival passes for 2 of the most creative car designs each weekend.</p>
              <p>Submit your Carpoolchella design concept today... or by April 3rd, 2024 at the latest!</p>
          </div>
          <div className="carpoolchella-body">
            <img src={carpoolGallery10} />
        </div>
      </div>

      <Reveal effect="animated fadeIn">

        <div className="carpoolchella-inner">
        <div className="carpoolchella-body reverse">
            <img className="carpoolchella-dd-logo" src={carpoolGallery6} />
            </div>
          <div className="carpoolchella-description reverse">
            <h1>How Do I Enter?</h1>
            <p>Simply fill out the <a href="#form">application form</a> below. Also remember to post your concept on Instagram/TikTok/Facebook to encourage others to carpool.</p>
            <p>Good Luck and Happy Carpooling!</p>
            <p>Read the <a href="/contest-rules" target="_blank">Official Rules</a></p>
            <p>Any questions? Contact us at <a href="mailto:pb@globalinheritance.org">pb@globalinheritance.org</a>.</p>
          </div>
        </div>
        </Reveal>
        

        <div className="carpoolchella-inner" style={{ marginTop: 100 }}>
          <Reveal effect="animated fadeIn">
            <div className="carpoolchella-gallery">
              <div className="carpoolchella-ex">
                <img src={carpoolGallery7} />
              </div>
              <div className="carpoolchella-ex">
                <img src={carpoolGallery4} />
              </div>
              <div className="carpoolchella-ex">
                <img src={carpoolGallery9} />
              </div>
            </div>
          </Reveal>
        </div>
        <div id="form" style={{ width: '100%', maxWidth: 780, margin: '0 auto' }}>
        <iframe className="airtable-embed" src="https://airtable.com/embed/appbKAdb2AoFy0IjM/pagV5tFs30gICsqrR/form" frameBorder="0" width="100%" height="533"></iframe>
        </div>
    </div>
    </Layout>
);
}

export default Carpool;


